.element-shadow {
  box-shadow: 0 4px 0px rgba(0, 0, 0, 0.25) !important;
}

#profile-icon button {
  width: 40px;
}

#profile-icon .dropdown-menu {
  border-width: 0;
  box-shadow: 0 4px 9px 0 var(--shadow-primary);
}

#profile-icon .dropdown-toggle::after {
  display: none;
}

/* login page styles */
.lr-card-dimensions {
  min-height: 500px;
  width: 70%;
  min-width: 350px;
  margin: 0 auto;
}

.lr-card-left-container small span {
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.login-container {
  height: 100vh;
}

.lr-card-left-container {
  background: var(--color-primary);
  color: #fff;
}

.lr-card-right-container {
  margin: 0 auto;
  background: #fff;
}

/* register */
.r-card-container {
  height: 650px;
}

.register-model-container {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  height: 100%;
}

.register-model-container .modal-header {
  padding: 0;
  border: none;
  align-items: normal;
}

.register-model-container .modal-header .btn-close {
  position: absolute;
  right: 10px;
  margin: 10px;
}
.register-model-container .modal-content {
  border: none;
  background: none;
  z-index: 1;
}

.register-model-container .modal-xl {
  width: 80%;
}

.register-model-container .modal-dialog-centered {
  min-height: 0%;
}

/* submit button */
.btn-submit {
  background: var(--color-secondary);
  color: var(--text-color-primary);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn-submit:hover {
  background: rgba(var(--color-secondary-rgb), 0.9);
  color: var(--text-color-primary);
}

/* side menu bar */
#side-menu h6 {
  text-wrap: wrap;
  color: var(--text-color-tertiary);
  font-family: var(--font-family);
}

#register-form input {
    color: #500000;
    font-size: 14px;
    border: solid 1px #AEADAD;
  }

#register-form .form-label {
  color: var(--text-color-tertiary);
  font-size: 14px;
  text-wrap: nowrap;
  margin-bottom: 0;
}

#register-form  .form-control {
  margin-bottom: 1rem;
}

/* user-extra-details */
#user-extra-details .col {
  padding-inline: 0;
  display: flex;
  flex-direction: column;
}

#user-extra-details .row {
  gap: 1rem;
  margin-bottom: 25px;
}

#user-extra-details label {
  color: #464646;
  opacity: 0.8;
  font-size: 14px;
  text-wrap: nowrap;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
}

#user-extra-details input {
  margin: 0px;
  padding: 1px;
}

#user-extra-details .css-9jacqo {
  padding: 10px 14px;
}

#user-extra-details .css-1jk99ih-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

#paper-details-card .card-subtitle{
  font-size: 0.8rem;
  color: var(--text-color-secondary);
  opacity: 0.5;
}

#paper-details-card .card-text{
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}


/* UserProfile component css */
#user-profile-component {
    max-width: 1000px;
}

#user-profile-component .user-details{
  min-width: 500px;
}

#user-profile-component .user-details div:nth-of-type(2) {
  font-size: 16px; /* You can change the styling here as needed */
}

#user-profile-component .social-icon-container > svg{
  width: 20px;
  height: 20px;
}

/* For screens smaller than 576px */
@media screen and (max-width: 576px) {
  .login-container {
    height: auto;
    overflow: auto;
  }
  .register-model-container .modal-xl {
    width: 90%;
  }
  .register-model-container .modal-dialog {
    margin-inline: auto;
  }

  /* user profile */
  #user-profile-component .user-details{
    min-width: 300px;
  }
}

/* For screens between 577px and 767px */
@media screen and (min-width: 577px) {
  .p-md-6 {
    padding: 4rem;
  }
  .register-model-container .modal-dialog {
    max-width: none;
    width: 80%;
  }
  .register-model-container .modal{
    width:auto;
}
}

/* For screens larger than 768px */
@media screen and (min-width: 768px) {
  /* .lr-card > .row {
        height: 600px;
    } */
}
@media screen and (min-width: 992px) {
  .lr-card > .row {
    height: 650px;
  }
  .lr-card-dimensions {
    min-width: 800px;
  }
  .r-card-container {
    height: 650px;
  }
  .register-model-container .modal-xl {
    min-width: 800px;
  }
}

@media screen and (min-width: 1200px) {
  .register-model-container .modal-xl {
    max-width: 1200px;
  }
}
