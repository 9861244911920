.customSecondaryOutlineBtn {
    font-weight: 600;
    color: var(--color-secondary)!important;
    border-color: var(--color-secondary)!important;
}

.customSecondaryOutlineBtn:hover {
    color: #333!important;
    background-color: var(--color-secondary)!important;
}

.fields {
    height: 40px!important;
}