@import 'bootstrap/dist/css/bootstrap.min.css';
@import url("https://unpkg.com/dropzone@5/dist/min/dropzone.min.css");

/* Global Reset */
* {
  margin: 0;
  padding: 0; 
  box-sizing: border-box;
}

:root {
  line-height: 1.5;
  font-weight: 400;
  --black:rgba(0, 0, 0, 1);
  --white: rgba(255, 255, 255, 1);
  --gray:rgb(215, 215, 215);
  --red:rgb(244, 0, 0);
  --color-primary: rgba(60, 170, 196, 1); /* #3CAAC4 */
  --color-secondary: rgba(255, 201, 66, 1); /* #FFC942 */
  --color-secondary-rgb: 255, 201, 66; /* #FFC942 */
  --color-tertiary: rgba(56, 56, 56, 1); /* #383838 */
  --text-color-primary: rgba(0, 0, 0, 1); /* #000000 */
  --text-color-secondary: rgba(56, 56, 56, 0.78); /* #383838C7 */
  --text-color-tertiary: rgba(124, 120, 120, 1); /* #7C7878 */
  --shadow-primary: rgba(0, 0, 0, 0.25); /* #00000040 */
  --font-style-primary: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --background-color-main: #ffffff;
}

/* Main container styles */
#root {
  margin: 0 auto;
  width: 100%; 
  max-width: 1904px; /* Maximum width of the container */
  background-color: var(--background-color-main, #ffffff);  
}

body {
  margin: 0;
  font-family: var(--font-style-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row, .col {
  margin-inline: 0;
  padding-inline: 0;
}


