.form-select {
    color: var(--text-color-secondary);
    padding: 8px;
    width: 100%;
    border-radius: 6px;
    border-color: #dbdbdb;
}

.form-select:focus {
    transition: 600ms;
    outline: 0;
    box-shadow: none;
}

.form-select:hover {
    background-color: #f8f9fa;
}

