.filterDropdownButton button {
    background-color: transparent!important;
    color: #808080;
}

.scrollable {
    overflow-x: scroll;
}

.scrollable::-webkit-scrollbar {
    display: none; /* Hides the scrollbar in WebKit browsers */
}

.scrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
