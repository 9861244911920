/* table.module.css */

.tableWrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tableHeader {
    margin-bottom: 12px;
}

.tableHeader,
.tableRow {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.headerCell {
    text-align: center;
    color: #808080;
}

.headerCell,
.cell {
    font-size: 15px!important;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 8px;
}

.task {
    min-width: 300px;
    width: 200px;
}

.assignee {
    min-width: 100px;
    width: 100px;
}

.priority {
    min-width: 120px;
    width: 120px;
}

.progress {
    min-width: 200px;
    width: 200px;
}

.comments {
    min-width: 150px;
    width: 120px;
}

.actions {
    min-width: 140px;
    width: 140px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.mainRowCheckbox {
    margin-right: 8px;
}

.subRow {
    padding-left: 50px;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.iconLink {
    font-size: 14px!important;
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: #333;
}


.actionIcons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.plusButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #3CAAC4;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    padding: 4px;
    width: 25px;
    height: 25px;
}

.plusButton:hover {
    background-color: #3899b0;
}

.truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 240px;
    vertical-align: middle;
    font-size: 14px!important;
}

.truncateSubText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 235px;
    vertical-align: middle;
}

.newTaskButton {
    width: max-content!important;
    background-color: #3CAAC4;
}

.newTaskButton:hover {
    background-color: #389db2 !important;
}

.noSubtasksMessage {
    padding: 0 10px 10px;
    font-style: italic;
    color: rgba(56, 56, 56, 0.6);
    text-align: center;
}

.drawerContent {
    height: 100%;
    width: 80vw;
    max-width: 400px;

    @media (min-width: 768px) {
        width: 40vw;
    }

    @media (min-width: 1200px) {
        width: 30vw;
    }
}

.commentTitle {
    font-size: 13px!important;
}

.commentBody {
    font-size: 15px!important;
    font-weight: 500;
}

.commentInput {
    border: 1px solid #3CAAC4!important;
}

.commentButton {
    height: 40px!important;
    background-color: #3CAAC4!important;
    color: white!important;
    border-radius: 100%!important;
}

.styledScroll::-webkit-scrollbar {
    width: 8px;
}

.styledScroll::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.styledScroll::-webkit-scrollbar-track {
    background: transparent;
}

.styledScroll {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}
