.review-paper-question-text {
    font-size: 15px!important;
}

.paper-card-text {
    max-width: 260px;
}

@media (max-width: 767px) {
    .review-paper-question-text {
        font-size: 12px!important;
    }

    .review-paper-input {
        width: 150px !important;
        float: inline-end;
    }

    .paper-card-text {
        max-width: 200px;
    }
}

@media (max-width: 425px) {
    .paper-card-text {
        max-width: 150px;
        width: 150px;
        font-size: 14px;
    }
}