.btn-home {
    background: #EFEFEF;
    color: var(--text-color-primary);
    border: none;
    cursor: pointer;
    font-size: 13px;
    padding: 10px 20px;
    border-radius: 0;
}

.btn-home-focus {
    background: #D9D9D9;
    color: var(--text-color-primary);
    border-bottom: 2px solid var(--color-primary);
}

.custom-btn {
    background: var(--color-secondary);
    color: var(--text-color-primary);
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.custom-btn:hover {
    background: rgba(var(--color-secondary-rgb), 0.8);
}

/* custom title */
.title-1 {
    font-size: 22px;
    font-weight:500;
    color: var(--text-color-primary);
}

.initiatives-grid .card-body {
    padding-inline: 0;
}

.initiatives-grid .col > div {
   width: 80%;
}

.initiatives-grid .card-img-top{
    border-radius: 0;
}

.custom-dropdown {
    background: #fff;
    border: 1px solid var(--color-primary);;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 20px;
}

#content-component .custom-dropdown .dropdown-toggle::after {
    margin-left: 80px;
}

#content-component .custom-dropdown > button,.initiative-dropdown> button {
    background: #fff;
    border: none;
    color: var(--text-color-primary);

}

.initiative-dropdown> button {
    font-size: 32px;
    padding: 0;
}

.initiative-dropdown> button:hover {
    background-color: #fff;
    color: var(--text-color-primary);
}

.initiative-dropdown> button:active {
    background-color: #fff;
    color: var(--text-color-primary);
}

.initiative-dropdown .dropdown-toggle::after {
    display: none;  
}

.custom-primary-outline-button{
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.custom-primary-outline-button:hover{
    color: var(--background-color-main);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

/* todo should move to modules */
.custom-dropzone {
    width: 300px;
    height: 170px;
    border-style: dashed;
    border-radius: 10px;
    cursor: pointer;
    border-color: #ced4da;
    text-align-last: center;
    align-content: center;
    padding: 0;
    transition: background-color 0.3s ease-in-out;
}

/* todo should move to modules */
.custom-dropzone:hover {
    background-color: #e9e9e988;
    color: #495057;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    display: inline;
}

#bread-crumb .css-1wuw8dw-MuiBreadcrumbs-separator {
    margin: 0;
    color: var(--color-primary);
}

/* preview-checkbox */
#preview-checkbox-form .form-check-input[type=checkbox] {
    border: 2px solid var(--color-secondary);
    margin: 20px;
    width: 20px;
    height: 20px;
}

#preview-checkbox-form .form-check-input[type=checkbox]:checked {
    background-color: var(--color-secondary);
}

#preview-checkbox-form .card-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color-primary);
}

/* supplementary-materials-page */
#supplementary-materials-page .row > div {
    padding-right: 0;
    padding-left: 0;
}

#supplementary-materials-page .supplementary-dz {
    border: 2px dashed #b0b8c0;
}

.supplementary-avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
}

/* project settings */
#project-settings input[type=text] {
    width: 50%;
    border-radius: 0;
}


@media screen and (max-width: 576px) {
    .custom-btn {
        font-size: 12px;
    }

    .title-1 {
        font-size: 18px;
    }

    .custom-dropdown {
        font-size: 12px;
    }

    .initiative-dropdown> button {
        font-size: 24px;
    }

    /* todo should move to modules */
    .custom-dropzone {
        width: 200px;
        height: 120px;
    }

    #preview-checkbox-form .form-check-input[type=checkbox] {
        margin: 10px;
        width: 15px;
        height: 15px;
    }

    #preview-checkbox-form .card-title {
        font-size: 14px;
        font-weight: 500;
    }

    .initiatives-grid .col > div {
        width: 100% !important;
        margin-bottom: 2rem;
    }    

    #project-settings input[type=text] {
        width: 100%;
    }
}