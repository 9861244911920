/* background colors */
.bg-custom-primary {
  background-color: var(--color-primary);
}

.bg-custom-secondary {
  background-color: var(--color-secondary);
}

.bg-custom-tertiary {
  background-color: var(--color-tertiary);
}

.bg-custom-gray {
  background-color: var(--gray);
}

/* text style */
.font-style-primary {
  background-color: var(--font-style-primary);
}

/* text colors */
.text-color-primary {
  color: var(--text-color-primary);
}

.text-color-secondary {
  color: var(--text-color-secondary);
}

.text-color-tertiary {
  color: var(--text-color-tertiary);
}

.text-color-cyan {
  color: var(--color-primary);
}

/* border colors */
.border-color-primary {
  border-color: var(--color-primary)!important;
}

.border-color-secondary {
  border-color: var(--color-secondary);
}
