#snackbar-provider .go946087465 {
    gap: 10px;
}

.reviewer-pagination .css-1v2f1lm-MuiButtonBase-root-MuiPaginationItem-root {
    margin: 0 10px;
    font-weight: bolder;
}
.reviewer-pagination li:first-child button,.reviewer-pagination li:last-child button {
    background-color: rgb(0, 0, 0);
    color: white;
    border-radius: 100%;
    opacity: 1;
}
.reviewer-pagination .css-mgtrg5-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    border: 1px solid rgb(0, 0, 0);
    background: var(--white);
    color: var(--black);
}

.reviewer-paper-review hr {
    padding: 3px;
    margin-block: 24px;
    border: none;
    background-color: var(--color-primary);
    width: 80px;
    opacity: 1;
}