.dialog-box {
    top : 10%;
}
.dialog-box .modal-dialog {
  max-width: 320px;
  border-radius: 0px;
}

.dialog-box .btn-close {
  opacity: 1;
}

.dialog-box .modal-content {
  border-radius: 3px;
}

.dialog-box .modal-header,
.dialog-box .modal-footer,
.dialog-box .modal-body {
  padding-inline: 30px;
}

.dialog-box .modal-body {
  padding-top: 0px;
}
