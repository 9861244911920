#reviewer-navigation {
    background-color: var(--color-primary);
}

#reviewer-sidebar ul li{
    list-style: none;
    margin-bottom: 0.6rem;
    display: flex;
    gap: 0.5rem;
    font-weight: bolder;
    cursor: pointer;
}
