
.reference-result-card {
    padding-inline: 18px !important;
    padding-block: 16px !important;
}

/* For devices with width less than 992px */
@media (max-width: 992px) {
    .reference-result-card {
        padding-inline: 15px !important;
        padding-block: 14px !important;
    }

    .reference-result-card .fs-4 {
        font-size: 18px !important;
    }

    .reference-result-card .fs-3 {
        font-size: 22px !important;
    }

    .reference-result-card span, .reference-result-card li {
        font-size: 15px !important;
    }
}

@media (max-width: 768px) {
    .reference-result-card {
        padding-inline: 12px !important;
        padding-block: 12px !important;
    }

    .reference-result-card .fs-4 {
        font-size: 16px !important;
    }

    .reference-result-card .fs-3 {
        font-size: 20px !important;
    }

    .reference-result-card span, .reference-result-card li {
        font-size: 14px !important;
    }
}

@media (max-width: 576px) {
    .reference-result-card {
        padding-inline: 10px !important;
        padding-block: 10px !important;
    }

    .reference-result-card .fs-4 {
        font-size: 15px !important;
    }

    .reference-result-card .fs-3 {
        font-size: 18px !important;
    }

    .reference-result-card span, .reference-result-card li {
        font-size: 13px !important;
    }
}
