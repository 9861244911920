#reviewer-login {
    height: 100vh;
    width: 100%;
    max-width: 360px;
    padding: 15px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#reviewer-login label[for="checkbox"] {
    color: var(--black);
    margin-left: 10px;
}

#reviewer-login .title {
    color: var(--black);
    font-weight: bolder;
    font-size: 2.5rem;
    line-height: 3;
}

#reviewer-login .form-group {
    margin-bottom: 24px;
}
#reviewer-login .form-group div {
    color: var(--red);
}

#reviewer-login input {
    border-radius: 0;
    background-clip: text;
    padding: 15px;
}

#reviewer-login input[type="checkbox" i]::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 0;
}