#custom-activity-calender ._container_1dwvo_15 {
  width: fit-content;
  transform-origin: 0 0;
}

/* for padding changes */
#custom-activity-calender ._calendar_1dwvo_19 {
  padding-inline: 20px !important;

}

/* color of activity dots */
#custom-activity-calender ._calendarCell_kpqwu_1 {
  outline: 0 !important;
  opacity: 0.8 !important;
}

#custom-activity-calender table {
  border-collapse: separate;
}

/* border spacing of the activities */
#custom-activity-calender ._table_1dwvo_25 {
    border-spacing: 5px;
}

#custom-activity-calender ._description_1fqsg_1 {
    padding-right: 25px !important;
}
  
  @media (max-width: 1024px) {
    #custom-activity-calender .calendar-box {
      overflow-x: scroll;;
    }
  }
